
import numeral from 'numeral';
import axios from 'axios';

export const formatPrice = (price) => {
  try {
    if (price === 0) return '';
    return price ? `$${numeral(price).format('0,0.00')}` : 'N/A';
  } catch (e) {
    return 'N/A'
  }
}

export const getLatestIndexData = async (indexId) => {
  try {
    const apiUrl = process.env.GATSBY_API_URL;
    const apiKey = process.env.GATSBY_API_KEY;
    const { data: indexLatestData = {} } = await axios.get(`${apiUrl}/v1/indices/${indexId}/latest`, { headers: { "X-Api-Key": apiKey } })
    const { price, market_cap } = indexLatestData;
    return { price, market_cap };
  } catch (e) {
    return { price: null, market_cap: null }
    //
  }
}